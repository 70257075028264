
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field } from "vee-validate";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import Contents from "@/components/reusable/ContentsAddition.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "addition-contents-house-insurance",
  components: { Form, Field, TotalPremiumWidget, Contents },
  data() {
    return {
      cartItemExists: false,
      displayForm: true,
      displayError: false,
      policies: [],
      phoneRate: 0.05,
      phoneRatePublicId: "",
      laptopRate: 0.02,
      laptopRatePublicId: "",
      cameraRate: 0.01,
      cameraRatePublicId: "",
      inverterRate: 0.0025,
      inverterRatePublicId: "",
      musicalInstrumentRate: 0.01,
      musicalInstrumentRatePublicId: "",
      jewelryRate: 0.01,
      jewelryRatePublicId: "",
      totalPremium: 0.0 as number,
      underwriters: [],
      policySummary: {},
      publicId: this.$route.params.publicId as string,
      housePolicyId: "",
      policy: "" as string,
      premiumCalculationRoute: "" as string,
      result: {
        contentPublicId: "",
        hasContentCartItem: true || (false as boolean)
      }
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const submitForm = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const form = document.forms[0];
            const formData = new FormData(form);

            formData.append("totalPremium", values["totalPremium"]);
            formData.append("housePolicyId", values["housePolicyId"]);
            formData.append("businessClass", "HOM");
            formData.append("businessType", "HOU");
            formData.append("package", "CON");
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              variables.CART_ADDITION_ROUTE + values["housePolicyId"],
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
              .then((response) => {
                console.clear();
                console.log(response);
                resetForm();
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: "insurance-packages-cart-debit-note",
                  params: {
                    publicId: response.data.data.cartItemPublicId
                  }
                });
              })
              .catch(function (error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Addition", [
        "Householders Insurance",
        "Contents"
      ]);
    });

    return {
      submitButton1,
      submitForm
    };
  },
  created() {
    this.hasExistingCartPackages();

    // Get owned building house insurance policies only
    this.getPolicies();

    // Get the rates for house bulding package
    this.getHouseBuildingRates();

    //Set page title
    document.title =
      "Addition: Contents Householders Insurance | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    hasExistingCartPackages() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CHECK_CART_PACKAGE_ROUTE}/CON/addition`)
          .then(({ data }) => {
            //Assign data to form fields
            this.result = data.data;
            this.cartItemExists = data.data.hasContentCartItem;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/package/HOU/CON`)
          .then(({ data }) => {
            //Assign data to props
            if (data.data != "") {
              this.displayError = false;
              this.policies = data.data;

              if (this.publicId != "") {
                this.policy = this.publicId;
                this.getPolicyInformation(this.publicId);
              }
            } else {
              this.displayError = true;
            }
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(value) {
      this.housePolicyId = value;
      this.premiumCalculationRoute = `${variables.CART_ROUTE}/premium/addition/${value}`;

      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/${value}`)
          .then(({ data }) => {
            //Assign data to variables
            this.policySummary = data.data.policySummary;
            this.totalPremium = data.data.policySummary.premium;
            this.displayForm = data.data != "" ? true : false;
          })
          .catch(function (error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getHouseBuildingRates() {
      //Fetch list house content insurance package rates
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.HOUSE_BUILDING_RATE_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.phoneRate = data.data.phone.value;
            this.phoneRatePublicId = data.data.phone.id;
            this.laptopRate = data.data.laptopOrTablet.value;
            this.laptopRatePublicId = data.data.laptopOrTablet.id;
            this.cameraRate = data.data.camera.value;
            this.cameraRatePublicId = data.data.camera.id;
            this.inverterRate = data.data.generatorOrInverter.value;
            this.inverterRatePublicId = data.data.generatorOrInverter.id;
            this.musicalInstrumentRate = data.data.musicalInstrument.value;
            this.musicalInstrumentRatePublicId = data.data.musicalInstrument.id;
            this.jewelryRate = data.data.jewelry.value;
            this.jewelryRatePublicId = data.data.jewelry.id;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    }
  }
});
